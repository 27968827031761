<div class="main_div">
  <div>
    <span style="color: #0054a6">Campaigns / </span
    ><span class="font_14">{{ campaign?.title }}</span>
  </div>

  <div class="main_container">
    <div class="left_side">
      <div
        class="img_slider"
        [ngStyle]="{
          'background-image': 'url(' + campaign?.cover_img_vdo?.url + ')'
        }"
      ></div>
      <div class="organizer_container mt-4">
        <div class="organizer">
          <img
            loading="lazy"
            alt="prochesta"
            src="../../../../../assets/images/icon/prochesta_foundation_icon.svg "
          />
          <span class="organized_text">
            Organized by
            <span style="font-weight: 500">
              {{ sessionUser?.company_name }}
            </span> </span
          ><img
            loading="lazy"
            alt="verified"
            src="../../../../../assets/images/icon/verified_icon_green.svg"
          />
        </div>
        <div class="time">
          <img
            style="margin-right: 8px"
            loading="lazy"
            alt=""
            src="../../../../../assets/images/icon/time-01.svg"
          /><span>{{
            campaign?.end_date
              ? getDaysLeft(campaign.end_date) + " Days Left"
              : "Ongoing"
          }}</span>
        </div>
      </div>

      <div style="margin-block: 32px">
        <div class="title">{{ campaign?.title }}</div>
        <div class="subtitle">{{ campaign?.subtitle }}</div>
      </div>
      <div class="our_story">Our Story:</div>
      <div id="campaign_story"></div>

      <hr class="line" />

      <!-- contact_container -->
      <div>
        <span class="contact_person_text">Contact Person</span>
        <div class="founder_child">
          <div class="founder_div">
            <div>
              <img
                src="../../../../../assets/images/round_profile.png"
                alt="Profile Picture"
                class="rounded-circle"
              />
            </div>
            <div class="founder_info">
              <div class="contact_details">
                <span class="ceo_name">{{
                  campaign?.contact_person_name
                }}</span>
                <span>{{ campaign?.contact_person_job_title }}</span>
                <span>{{ campaign?.contact_person_information }}</span>
                <button class="white-button-md">Contact</button>
              </div>
            </div>
          </div>
          <div>
            <img
              src="../../../../../assets/images/icon/next_blue_icon.svg"
              alt="Profile Picture"
              loading="lazy"
            />
          </div>
          <div class="d-flex" style="gap: 16px">
            <div>
              <img
                src="../../../../../assets/images/icon/organization_icon.svg"
                alt="Profile Picture"
                loading="lazy"
                style="height: 90px; width: 90px"
              />
            </div>
            <div>
              <div
                class="d-flex align-items-center"
                style="margin-bottom: 16px"
              >
                <span class="org_title">{{ sessionUser?.company_name }}</span>
              </div>
              <div class="contact_details">
                <div>Bangladesh</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="line" />
    </div>

    <div class="right_side">
      <div class="donation">
        <div class="donation_details d-flex flex-column gap-3">
          <div class="amount">
            <span class="font_18 fw-bold">
              Total - ৳{{ campaign?.total_fund_raised | number }}
            </span>
            <span class="font_16 fw-normal"
              >{{
                (campaign.total_fund_raised / campaign.fundraising_target) * 100
                  | percent
              }}
              of Target of
              {{ campaign?.fundraising_target | number }}
              Goal
            </span>
          </div>

          <div>
            <dx-progress-bar
              class="progress"
              [min]="0"
              [max]="campaign.fundraising_target"
              [value]="campaign.total_fund_raised"
              [showStatus]="false"
              [hint]="
                (campaign?.total_fund_raised / campaign?.fundraising_target) *
                  100 | percent
              "
            ></dx-progress-bar>

            <div class="amount mt-2">
              <div class="d-flex align-items-center gap-1">
                <img src="./assets/images/icon/user-multiple.svg" />
                <span class="font_14">
                  {{ campaign?.donation_count || 0 | number }} Donations</span
                >
              </div>
              <div class="d-flex align-items-center gap-1">
                <img src="./assets/images/icon/funds_used_icon_black.svg" />
                <span class="font_16 fw-normal">
                  Matching - {{ campaign?.matching_percentage / 100 | percent }}
                </span>
              </div>
            </div>
          </div>
          <button class="blue-button-lg w-100" (click)="donationPopup = true">
            Donate
          </button>
          <button class="yellow-button-lg w-100" text="share">
            Explore Other Campaigns
          </button>
          <div class="text-center mt-4 mb-4">
            <img
              loading="lazy"
              src="../../../../../assets/images/icon/trending_up_icon.svg"
              alt="Donor"
              title="donor"
            />
            <span class="font_16 recent_donated_text">{{
              campaign?.donation_count
                ? campaign?.donation_count + " people just donated"
                : "0 donations yet"
            }}</span>
          </div>

          <div
            *ngIf="campaign?.donation_count > 0"
            class="d-flex flex-column gap-4 mb-4"
          >
            <div class="donor_list">
              <div class="donor_icon_div">
                <img
                  src="../../../../../assets/images/icon/donor_icon_blue.png"
                  alt="Donor"
                  title="donor"
                />
              </div>
              <div class="d-flex gap-2 flex-column">
                <span class="font_18">{{
                  campaign.donation_summary?.recent_donation?.name
                }}</span>
                <span class="font_16">
                  ৳{{
                    campaign.donation_summary?.recent_donation?.amount ==
                    (0 | number)
                      ? "Hidden"
                      : (campaign.donation_summary.recent_donation.amount
                        | number)
                  }}

                  <span class="font_14" style="text-decoration: underline"
                    >Recent Donation</span
                  >
                </span>
              </div>
            </div>
            <div class="donor_list">
              <div class="donor_icon_div">
                <img
                  src="../../../../../assets/images/icon/donor_icon_blue.png"
                  alt="Donor"
                  title="donor"
                />
              </div>
              <div class="d-flex gap-2 flex-column">
                <span class="font_18">{{
                  campaign.donation_summary?.top_donation?.name
                }}</span>
                <span class="font_16">
                  ৳{{
                    campaign.donation_summary?.top_donation?.amount ==
                    (0 | number)
                      ? "Hidden"
                      : (campaign.donation_summary.top_donation.amount | number)
                  }}
                  <span class="font_14" style="text-decoration: underline"
                    >Top Donation</span
                  >
                </span>
              </div>
            </div>
            <div class="donor_list">
              <div class="donor_icon_div">
                <img
                  src="../../../../../assets/images/icon/donor_icon_blue.png"
                  alt="Donor"
                  title="donor"
                />
              </div>
              <div class="d-flex gap-2 flex-column">
                <span class="font_18">{{
                  campaign.donation_summary?.first_donation?.name
                }}</span>
                <span class="font_16">
                  ৳{{
                    campaign.donation_summary?.first_donation?.amount ==
                    (0 | number)
                      ? "Hidden"
                      : (campaign.donation_summary.first_donation.amount
                        | number)
                  }}
                  <span class="font_14" style="text-decoration: underline"
                    >First Donation</span
                  >
                </span>
              </div>
            </div>
          </div>
          <button
            class="white-button-md w-100 mb-2"
            [disabled]="campaign?.donation_count === 0"
          >
            See All
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-popup
  [width]="'50vw'"
  [height]="'850px'"
  [(visible)]="donationPopup"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [showTitle]="true"
  [title]="'Make Your Contribution'"
>
  <div class="left_side_">
    <div class="left_total_donation">
      <div class="enter_donation_amount requiredMark">Donation Amount</div>
      <div class="suggested_all_amount p-2">
        <div
          class="amount_"
          *ngFor="let fund of suggestedFund; let i = index"
          [class.selected]="fund?.isSelected"
          (click)="selectAmount(i)"
        >
          {{ fund.amount | number }}
        </div>
      </div>
      <div class="total_amount">
        <div>৳</div>
        <dx-number-box
          class="amount_tk"
          placeholder=""
          [invalidValueMessage]="'Value must be a number'"
          [format]="',##0'"
          [valueChangeEvent]="'input'"
          [min]="10"
          [(ngModel)]="selectedAmount"
        >
          <dx-validator validationGroup="validationGrp">
            <dxi-validation-rule
              type="required"
              message="Target amount is required"
            ></dxi-validation-rule>
            <dxi-validation-rule
              type="range"
              [min]="10"
              message="Minimum amount 10 tk"
            ></dxi-validation-rule>
          </dx-validator>
        </dx-number-box>
      </div>
    </div>

    <div class="donation_details_">
      <div class="donation_amount_transaction_fees">
        <div class="donation_amount">
          <div class="donation_name">Donation</div>
          <div class="donation_number">
            ৳
            {{
              coverFees
                ? (selectedAmount | number)
                : (selectedAmount - selectedAmount * 0.14 | number)
            }}
          </div>
        </div>
        <div class="donation_amount">
          <div class="transaction_fees_title">Total transaction fees</div>
          <div class="transaction_amount">
            ৳ {{ selectedAmount * 0.14 | number }}
          </div>
        </div>
        <div class="_100_of_your">
          100% of your donation goes to {{ campaign?.title }}
        </div>
      </div>
      <div class="donation_inf">
        <div class="_100_of_your">
          Processing fees are always charged by companies like SSL, Bkash or
          Bank, no matter what platform you use. Shohay makes the Processing
          Fees 100% transparent and takes 0% of any amount.
        </div>
        <div class="confirmation">
          <p class="cursor">
            <dx-check-box
              [elementAttr]="{ 'aria-label': 'Checked' }"
              class="checkbox"
              text="I’ll cover the transaction fee"
              [(ngModel)]="coverFees"
            >
            </dx-check-box>
          </p>
        </div>
      </div>

      <div class="innet_pop_total_">
        <div class="donation_name">Total Amount</div>
        <div class="transaction_amount">
          ৳
          {{
            coverFees
              ? (selectedAmount + selectedAmount * 0.14 | number)
              : (selectedAmount | number)
          }}
        </div>
      </div>
    </div>
    <div class="_100_of_your text-dark">
      On your Contribution company will match
      {{ campaign?.matching_percentage / 100 | percent }} on your donation.
    </div>
    <div class="donate_payment">
      <div class="pay_buttons">
        <div class="yellow-button-lg fw-bold cursor w-100">
          Pledge Now, Pay Later
        </div>
        <div (click)="donate()" class="blue-button-lg fw-bold cursor w-100">
          Donate Now
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="openThankYouPopup"
  [height]="'auto'"
  [width]="'70vw'"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="root">
    <div class="bookmarks">
      <img
        class="icons8-no-bookmark-100-1"
        loading="lazy"
        alt=""
        src="../../../../assets/images/icon/right-green.svg"
      />
    </div>
    <section class="you-havent-bookmarked">
      Thank You for Your Contribution {{ sessionUser.name }}
    </section>
    <div class="feedback">
      You have successfully donated ৳ {{ donationBody?.amount | number }} to “{{
        campaign?.title
      }}”
    </div>
    <div class="feedback">
      A receipt has been sent to your email: {{ sessionUser?.email }}.
    </div>
    <div class="bookmarks" style="margin-top: 32px">
      <dx-button
        class="yellow-button-lg cursor"
        [routerLink]="['/employee/campaigns/dashboard']"
      >
        Explore Campaigns
      </dx-button>
      <dx-button
        class="blue-button-lg cursor"
        (onClick)="openPaymentReceiptPopup()"
      >
        Download Receipt
      </dx-button>
    </div>

    <div class="lower_part_popup">
      <div class="lower_part_popup_left">
        <div class="lower_part_popup_left_title">Share Your Contribution</div>
        <div class="url_link_container">
          <dx-text-box
            class="myTextBox"
            [showClearButton]="false"
            [readOnly]="true"
            [(value)]="url"
          >
            <dx-validator validationGroup="validationGrp"> </dx-validator>
          </dx-text-box>
          <div class="copy_text" (click)="copyText()">
            <img
              src="../../../../assets/images/icon/copy_text_blue_icon.svg"
              alt="facebook"
              title="facebook"
            />
            <div class="copy_text">Copy</div>
          </div>
        </div>
        <div class="social_media_div">
          <div class="social_media" (click)="shareOnSocialMedia('facebook')">
            <img
              src="../../../../assets/images/article-news/icon/2023_Facebook_icon 1.svg"
              alt="facebook"
              title="facebook"
            />
          </div>
          <div class="social_media" (click)="shareOnSocialMedia('linkedin')">
            <img
              src="./assets/images/logo/linkedin_logo.svg"
              alt="linked"
              title="linkedin"
            />
          </div>
          <div class="social_media" (click)="shareOnSocialMedia('whatsapp')">
            <img
              src="./assets/images/icon/whatsapp_icon.svg"
              alt="facebook"
              title="facebook"
            />
          </div>
          <div class="social_media" (click)="shareOnSocialMedia('twitter')">
            <img
              src="./assets/images/icon/x_twitter_icon.svg"
              alt="facebook"
              title="facebook"
            />
          </div>
        </div>
      </div>
      <div class="lower_part_popup_left">
        <div class="growth_graph">
          <img
            class="icons8-no-bookmark-100-1"
            loading="lazy"
            alt=""
            src="../../../../assets/images/icon/growth-graph.svg"
          />
        </div>
        <div class="lower_part_popup_left_title">
          Track Your Donation Impact
        </div>
        <dx-button class="blue-button-lg">View Campaign Updates</dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<app-receipts [receipt]="receipt"></app-receipts>
