<div class="main_div">
  <div style="margin-bottom: 16px">
    <span class="colored">Home / </span> Account Settings
  </div>
  <div class="main_body_title" style="margin-bottom: 16px">
    Employee Profile
  </div>
  <div class="completeness" style="margin-bottom: 16px">
    <!-- <dx-circular-gauge
      id="circularGauge"
      [value]="progress"
      [startValue]="0"
      [endValue]="100"
      [size]="{ width: 100, height: 100 }"
      [geometry]="{ startAngle: 180, endAngle: 540 }"
      [rangeContainer]="{
        offset: 10,
        ranges: [{ startValue: 0, endValue: 100, color: '#d3e2f9' }]
      }"
      [valueIndicator]="{
        type: 'rangeBar',
        color: '#1e88e5',
        offset: 10
      }"
      [label]="{
        visible: true,
        customizeText: gaugeLabel
      }"
    >
    </dx-circular-gauge> -->
    <div class="title_subtitle">
      <div class="title_">Completeness of Employee Profile </div>
      <div class="desc">Last updated on: {{ profile?.updated_at | date : "dd MMM YYYY" }}</div>
    </div>
  </div>
  <div class="main_body">
    <div class="upper_part">
      <div class="title_img">
        <div class="img">
          <img
            class="img1"
            src="./assets/images/icon/profile_icon_gray.png"
            alt="pro_pic"
          />
          <img
            class="img2"
            src="./assets/images/icon/upload_camera.svg"
            alt="cam_icon"
          />
        </div>
        <div class="title_edit">
          <div *ngIf="profile?.name" class="title">{{ profile?.name }}</div>
          <div class="edit_mode">
            <img
              (click)="toggleEditMode()"
              class="cursor"
              [src]="
                editMode
                  ? '/assets/images/icon/pencil-edit-blue.svg'
                  : '/assets/images/icon/pencil-edit.svg'
              "
            />
          </div>
        </div>
      </div>
      <div class="about_connected">
        <div class="about_title_desc">
          <div class="about">About me</div>
          <div class="desc" style="width: 100%">
            @if(editMode){
            <dx-text-area
              class="custom-fill"
              placeholder="Write About You"
              [height]="100"
              [maxLength]="500"
              [(ngModel)]="updateBody.about"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule type="required" message="this is required">
                </dxi-validation-rule> -->
              </dx-validator>
            </dx-text-area>
            }@else {
            <div class="profile_desc">
              {{
                updateBody.about
                  ? updateBody.about
                  : "CLick on Edit button to write about you"
              }}
            </div>
            }
          </div>
        </div>
        <div class="connected">
          <div class="about">Connected Company</div>
          <img
            src="/assets/images/logo/bmqa-logo-name.png"
            alt="connected company"
          />
        </div>
      </div>
    </div>
    <div class="lower_part">
      <div
        class="full_panel row d-flex justify-content-between align-items-center"
      >
        <div class="select_panel col d-flex p-0">
          <dx-tab-panel
            (onSelectionChanged)="onTabChange($event)"
            [selectedIndex]="activeTabIndex"
            style="box-shadow: none"
            [swipeEnabled]="true"
            [scrollByContent]="true"
            [showNavButtons]="true"
          >
            <dxi-item title="Basic Information"> </dxi-item>
            <dxi-item title="Corporate Information"> </dxi-item>
            <dxi-item
              title="Corporate Giving Preferences
                        "
            >
            </dxi-item>
            <dxi-item
              title="Profile Settings
                        "
            >
            </dxi-item>
          </dx-tab-panel>
        </div>
      </div>
    </div>
    @if(activeTabIndex === 0){
    <div class="details_part">
      <div class="left_details">
        <div class="profile_name">
          <div class="profile_title">Name</div>
          <!-- @if(editMode){
          <dx-text-area
            class="custom-fill"
            placeholder="Write your name"
            [height]="56"
            [maxLength]="500"
            [(ngModel)]="profile.name"
            [readOnly]="true"
          >
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-text-area>
          }@else { -->
          <div *ngIf="profile?.name" class="profile_desc">
            {{
              profile?.name
                ? profile?.name
                : "CLick on Edit button to write your name"
            }}
          </div>
          <!-- } -->
        </div>
        <div class="profile_name">
          <div class="profile_title">Phone Number</div>
          <!-- @if(editMode){
          <dx-text-box
            [readOnly]="true"
            class="custom-fill"
            placeholder="Enter your Phone"
          >
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-text-box>
          }@else { -->
          <div class="profile_desc">No available Phone Number</div>
          <!-- } -->
        </div>
        <div class="profile_name">
          <div class="profile_title">Gender</div>
          <!-- @if(editMode){
          <dx-select-box
            class="input_field"
            [dataSource]="['Male', 'Female']"
            placeholder="Select..."
            [readOnly]="true"
          >
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-select-box>
          }@else { -->
          <div class="profile_desc">Male</div>
          <!-- } -->
        </div>
      </div>
      <div class="right_details">
        <div class="profile_name">
          <div class="profile_title">Email</div>
          <!-- @if(editMode){
          <dx-text-box
            class="custom-fill"
            placeholder="Enter your email address"
            [(ngModel)]="profile.email"
            [readOnly]="true"
          >
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-text-box>
          }@else{ -->
          <div *ngIf="!profile?.email" class="profile_desc">
            {{ profile?.email ? profile?.email : "No email found" }}
            <span *ngIf="profile?.email" class="verified">Verified</span>
          </div>
          <!-- } -->
        </div>
        <div class="profile_name">
          <div class="profile_title">Date Of Birth</div>
          <!-- @if(editMode){
          <dx-date-box
            class="input_field"
            placeholder="dd/mm/yy"
            [displayFormat]="'dd/MM/yyyy'"
            [showClearButton]="true"
            [readOnly]="true"
          >
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-date-box> -->
          <!-- <dx-text-area
            class="custom-fill"
            placeholder="Write your occupation here"
            [height]="80"
            [maxLength]="500"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule type="required" message="this is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-area> -->
          <!-- }@else { -->
          <div class="profile_desc">No Birth Date available</div>
          <!-- } -->
        </div>
        <div class="profile_name">
          <div class="profile_title">Address</div>
          <!-- @if(editMode){
          <dx-text-area
            class="custom-fill"
            placeholder="Write your address here"
            [height]="80"
            [maxLength]="500"
            [readOnly]="true"
          >
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-text-area>
          }@else { -->
          <div class="profile_desc">
            No address option now
          </div>

          <!-- } -->
        </div>
      </div>
    </div>
    <!-- <div class="button_container">
      <dx-button *ngIf="editMode" class="blue-button-lg">Save</dx-button>
    </div> -->
    } @if(activeTabIndex === 1){
    <div class="details_part">
      <div class="left_details">
        <div class="profile_name">
          <div class="profile_title">Employee ID</div>
          @if(editMode){
          <dx-text-box
            [(ngModel)]="updateBody.office_id"
            class="custom-fill"
            placeholder="e.g, 12834"
          >
            <dx-validator validationGroup="validationGrp">
              <!-- <dxi-validation-rule type="required" message="This is required">
              </dxi-validation-rule> -->
            </dx-validator>
          </dx-text-box>
          }@else {
          <div class="profile_desc">{{ updateBody?.office_id ? updateBody?.office_id : 'Click on edit option to add id'  }}</div>
          }
        </div>
        <div class="profile_name">
          <div class="profile_title">Department</div>
          @if(editMode){
            <dx-select-box
            class="custom-fill"
            [dataSource]="departmentList"
            placeholder="Select Department"
            [(value)]="updateBody.department_name"
            (onValueChanged)="onDepartmentChange($event)"
            >
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-select-box>
          <!-- <dx-text-box
            class="custom-fill"
            placeholder="e.g, Finance And Account"
            [(ngModel)]="updateBody.department_name"
          >
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-text-box> -->
          }@else {
          <div class="profile_desc">{{ updateBody.department_name }}</div>
          }
        </div>
      </div>
      <div class="right_details">
        <div class="profile_name">
          <div class="profile_title">Joining Date</div>
          @if(editMode){
          <dx-date-box
            class="input_field"
            placeholder="dd/mm/yy"
            [displayFormat]="'dd/MM/yyyy'"
            [showClearButton]="true"
            [(ngModel)]="updateBody.joining_date"
          >
            <dx-validator validationGroup="validationGrp">
              <!-- <dxi-validation-rule
                type="required"
                message="Start Date is required"
              ></dxi-validation-rule> -->
            </dx-validator>
          </dx-date-box>
          <!-- <dx-text-area
            class="custom-fill"
            placeholder="Write your occupation here"
            [height]="80"
            [maxLength]="500"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule type="required" message="this is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-area> -->
          }@else {
          <div class="profile_desc">{{ updateBody.joining_date | date }}</div>
          }
        </div>
        <div class="profile_name">
          <div class="profile_title">Designation</div>
          @if(editMode){
          <dx-text-box
            [(ngModel)]="updateBody.designation"
            class="custom-fill"
            placeholder="e.g, Senior Executive"
          >
            <!-- <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule type="required" message="This is required">
              </dxi-validation-rule>
            </dx-validator> -->
          </dx-text-box>
          }@else {
          <div class="profile_desc">
            {{
              updateBody.designation ? updateBody.designation : "No designation now"
            }}
          </div>
          }
        </div>
      </div>
    </div>
    <!-- <div class="button_container">
      <dx-button *ngIf="editMode" class="blue-button-lg">Save</dx-button>
    </div> -->
    }@if(activeTabIndex === 2){
    <div class="details_part">
      <div class="left_details">
        <div class="profile_name">
          <div class="profile_title">Skills/Expertise</div>
          @if(editMode){
          <dx-text-box
            class="custom-fill"
            placeholder="e.g., Fundraising, Project Management"
            [(ngModel)]="updateBody.skills"
          >
            <dx-validator validationGroup="validationGrp">
              <!-- <dxi-validation-rule type="required" message="This is required">
              </dxi-validation-rule> -->
            </dx-validator>
          </dx-text-box>
          }@else {
          <div class="profile_desc">
            {{ updateBody.skills ? updateBody.skills : "add  your skills" }}
          </div>
          }
        </div>
        <div class="profile_name">
          <div class="profile_title">Volunteer Hours Contributed</div>
          @if(editMode){
          <dx-number-box [(ngModel)]="updateBody.volunteer_hours" class="custom-fill" placeholder="e.g., 40">
            <dx-validator validationGroup="validationGrp">
              <!-- <dxi-validation-rule type="required" message="This is required">
              </dxi-validation-rule> -->
            </dx-validator>
          </dx-number-box>
          }@else {
          <div class="profile_desc">{{updateBody.volunteer_hours}}</div>
          }
        </div>
        <!-- <div class="profile_name">
          <div class="profile_title">Select Payment Method</div>
          @if(editMode){
          <dx-select-box
            class="input_field"
            [dataSource]="['Bkash', 'Nogod', 'Card']"
            placeholder="Select Payment Method..."
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="select an option"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
          }@else {
          <div class="profile_desc">Card</div>
          }
        </div> -->
      </div>
      <div class="right_details last_right">
        <div class="profile_name">
          <div class="profile_title">Preferred Causes</div>
          @if(editMode){
          <dx-text-area
            class="custom-fill"
            placeholder="e.g., Education, Health"
            [height]="125"
            [maxLength]="500"
            [(ngModel)]="updateBody.causes"
          >
            <dx-validator validationGroup="validationGrp">
              <!-- <dxi-validation-rule type="required" message="this is required">
              </dxi-validation-rule> -->
            </dx-validator>
          </dx-text-area>
          }@else {
          <div class="profile_desc">{{updateBody.causes}}</div>
          }
        </div>
      </div>
    </div>
    <!-- <div class="button_container">
      <dx-button *ngIf="editMode" class="blue-button-lg">Save</dx-button>
    </div> -->
    }@if(activeTabIndex === 3){
    <div class="details_part">
      <div class="left_details">
        <div class="profile_name">
          <div class="profile_title">Current Password</div>
          <!-- @if(editMode){
          <dx-text-box [readOnly]="true" class="custom-fill" placeholder="Enter Current Password">
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-text-box>
          }@else { -->
          <div class="profile_desc">********</div>
          <!-- } -->
        </div>
        <div class="profile_name">
          <div class="profile_title">New Password</div>
          <!-- @if(editMode){
          <dx-text-box [readOnly]="true"] class="custom-fill" placeholder="Enter New Password">
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-text-box>
          }@else { -->
          <div class="profile_desc">********</div>
          <!-- } -->
        </div>
      </div>
      <div class="right_details last_right">
        <div class="profile_name">
          <div class="profile_title">Confirm New Password</div>
          <!-- @if(editMode){
          <dx-text-box [readOnly]="true" class="custom-fill" placeholder="Re-enter New Password">
            <dx-validator validationGroup="validationGrp">
            </dx-validator>
          </dx-text-box>
          }@else { -->
          <div class="profile_desc">********</div>
          <!-- } -->
        </div>
      </div>
    </div>
    }
    <div class="button_container">
      <dx-button (onClick)="updateProfile()" *ngIf="editMode" class="blue-button-lg">Save</dx-button>
    </div>
  </div>
</div>
