import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { EmployeeRoutingModule } from './employee-routing.module';
import { EmployeeCampaignDashboardComponent } from './employee-campaign-dashboard/employee-campaign-dashboard.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeCampaignDetailsComponent } from './employee-campaign-details/employee-campaign-details.component';
import { CorporateEmployeeProfileComponent } from './corporate-employee-profile/corporate-employee-profile.component';
import { CorporateEmployeeDashboardComponent } from '../employee/corporate-employee-dashboard/corporate-employee-dashboard.component';
import { CorporateEmployeeLeaderboardComponent } from '../employee/corporate-employee-leaderboard/corporate-employee-leaderboard.component';

@NgModule({
  declarations: [
    EmployeeCampaignDashboardComponent,
    EmployeeCampaignDetailsComponent,
    CorporateEmployeeProfileComponent,
    CorporateEmployeeDashboardComponent,
    CorporateEmployeeLeaderboardComponent,
    
  ],
  imports: [
    EmployeeRoutingModule,
    CommonModule,
    DevExtremeModule,
    FormsModule,
    SharedModule,
    NgbCarouselModule,
  ],
})
export class EmployeeModule {}
