export class CorporateEmployeeProfileModel {
  about: string = null;
  office_id: string = null;
  department_id: Object = {};
  department_name: string = null;
  designation: string = null;
  joining_date: string = null;
  skills: string = null;
  volunteer_hours: number = null;
  causes: string = null;
  additional_info: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}
