import { Component } from '@angular/core';
import { CorporateCampaignService } from 'src/app/shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-corporate-employee-leaderboard',
  templateUrl: './corporate-employee-leaderboard.component.html',
  styleUrl: './corporate-employee-leaderboard.component.scss',
})
export class CorporateEmployeeLeaderboardComponent {
  public employeeLeaderboard: any = [
    // {
    //   id: 1,
    //   rank: './assets/images/icon/trophy_0.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   department: 'IT',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 2,
    //   rank: './assets/images/icon/trophy_1.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   department: 'IT',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 3,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   department: 'IT',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 4,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   department: 'IT',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 5,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   department: 'IT',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 6,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   department: 'IT',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 7,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   department: 'IT',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 8,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   department: 'IT',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 9,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   department: 'IT',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
  ];
  public employeeTeams: any = [
    // {
    //   id: 1,
    //   rank: './assets/images/icon/trophy_0.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 2,
    //   rank: './assets/images/icon/trophy_1.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 3,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 4,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 5,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
    // {
    //   id: 6,
    //   rank: './assets/images/icon/trophy_2.svg',
    //   profile: './assets/images/icon/profile_icon_gray.png',
    //   employee_name: 'Mahmudul Hasan',
    //   contribution: ' 15,20,00,000',
    //   Campaigns_Participate: '12',
    // },
  ];
  public slicedEmployeeLeaderboardList: any = [];
  public slicedEmployeeTeam: any = [];
  public employeeLeaderboardPopup: boolean = false;
  public employeeTeamPopup: boolean = false;

  public today: Date = new Date();
  public oneYearAgo: Date = new Date(
    this.today.getFullYear() - 1,
    this.today.getMonth(),
    this.today.getDate(),
    this.today.getHours(),
    this.today.getMinutes(),
    this.today.getSeconds(),
    this.today.getMilliseconds()
  );

  public startDate: Date = this.oneYearAgo;
  public endDate: Date = this.today;

  private companyId: string = null;

  
  public leaderBoard = [
    // {
    //   created_at: '2025-01-30T10:15:30.123Z',
    //   created_by: 'admin',
    //   updated_at: '2025-01-30T12:20:45.456Z',
    //   updated_by: 'admin',
    //   is_active: true,
    //   id: '001',
    //   company_id: 'C-123',
    //   user_id: 'U-1001',
    //   employee_id: 'E-5001',
    //   department_id: 'D-2001',
    //   department_name: 'Marketing',
    //   email: 'john.doe@example.com',
    //   name: 'John Doe',
    //   phone: '+1-555-1234',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: true,
    //   campaign_ids: ['CMP-101', 'CMP-202'],
    //   team_ids: ['T-100', 'T-200'],
    //   first_donation_day: '05',
    //   last_donation_day: '20',
    //   last_donation_month: 'January',
    //   last_donation_year: '2025',
    //   total_amount: 500,
    //   last_donation_amount: 100,
    //   total_donation: 5,
    // },
    // {
    //   created_at: '2025-01-15T08:45:15.789Z',
    //   created_by: 'admin',
    //   updated_at: '2025-01-25T09:50:20.321Z',
    //   updated_by: 'admin',
    //   is_active: true,
    //   id: '002',
    //   company_id: 'C-456',
    //   user_id: 'U-2002',
    //   employee_id: 'E-6002',
    //   department_id: 'D-3002',
    //   department_name: 'Finance',
    //   email: 'jane.smith@example.com',
    //   name: 'Jane Smith',
    //   phone: '+1-555-5678',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: false,
    //   campaign_ids: ['CMP-303'],
    //   team_ids: ['T-300'],
    //   first_donation_day: '10',
    //   last_donation_day: '18',
    //   last_donation_month: 'December',
    //   last_donation_year: '2024',
    //   total_amount: 750,
    //   last_donation_amount: 250,
    //   total_donation: 3,
    // },
    // {
    //   created_at: '2025-02-05T14:30:10.654Z',
    //   created_by: 'admin',
    //   updated_at: '2025-02-10T16:45:22.987Z',
    //   updated_by: 'admin',
    //   is_active: false,
    //   id: '003',
    //   company_id: 'C-789',
    //   user_id: 'U-3003',
    //   employee_id: 'E-7003',
    //   department_id: 'D-4003',
    //   department_name: 'IT',
    //   email: 'alice.jones@example.com',
    //   name: 'Alice Jones',
    //   phone: '+1-555-9876',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: true,
    //   campaign_ids: ['CMP-404', 'CMP-505'],
    //   team_ids: ['T-400', 'T-500'],
    //   first_donation_day: '12',
    //   last_donation_day: '28',
    //   last_donation_month: 'November',
    //   last_donation_year: '2024',
    //   total_amount: 1200,
    //   last_donation_amount: 400,
    //   total_donation: 6,
    // },
    // {
    //   created_at: '2025-01-30T10:15:30.123Z',
    //   created_by: 'admin',
    //   updated_at: '2025-01-30T12:20:45.456Z',
    //   updated_by: 'admin',
    //   is_active: true,
    //   id: '001',
    //   company_id: 'C-123',
    //   user_id: 'U-1001',
    //   employee_id: 'E-5001',
    //   department_id: 'D-2001',
    //   department_name: 'Marketing',
    //   email: 'john.doe@example.com',
    //   name: 'John Doe',
    //   phone: '+1-555-1234',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: true,
    //   campaign_ids: ['CMP-101', 'CMP-202'],
    //   team_ids: ['T-100', 'T-200'],
    //   first_donation_day: '05',
    //   last_donation_day: '20',
    //   last_donation_month: 'January',
    //   last_donation_year: '2025',
    //   total_amount: 500,
    //   last_donation_amount: 100,
    //   total_donation: 5,
    // },
    // {
    //   created_at: '2025-01-15T08:45:15.789Z',
    //   created_by: 'admin',
    //   updated_at: '2025-01-25T09:50:20.321Z',
    //   updated_by: 'admin',
    //   is_active: true,
    //   id: '002',
    //   company_id: 'C-456',
    //   user_id: 'U-2002',
    //   employee_id: 'E-6002',
    //   department_id: 'D-3002',
    //   department_name: 'Finance',
    //   email: 'jane.smith@example.com',
    //   name: 'Jane Smith',
    //   phone: '+1-555-5678',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: false,
    //   campaign_ids: ['CMP-303'],
    //   team_ids: ['T-300'],
    //   first_donation_day: '10',
    //   last_donation_day: '18',
    //   last_donation_month: 'December',
    //   last_donation_year: '2024',
    //   total_amount: 750,
    //   last_donation_amount: 250,
    //   total_donation: 3,
    // },
    // {
    //   created_at: '2025-02-05T14:30:10.654Z',
    //   created_by: 'admin',
    //   updated_at: '2025-02-10T16:45:22.987Z',
    //   updated_by: 'admin',
    //   is_active: false,
    //   id: '003',
    //   company_id: 'C-789',
    //   user_id: 'U-3003',
    //   employee_id: 'E-7003',
    //   department_id: 'D-4003',
    //   department_name: 'IT',
    //   email: 'alice.jones@example.com',
    //   name: 'Alice Jones',
    //   phone: '+1-555-9876',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: true,
    //   campaign_ids: ['CMP-404', 'CMP-505'],
    //   team_ids: ['T-400', 'T-500'],
    //   first_donation_day: '12',
    //   last_donation_day: '28',
    //   last_donation_month: 'November',
    //   last_donation_year: '2024',
    //   total_amount: 1200,
    //   last_donation_amount: 400,
    //   total_donation: 6,
    // },
    // {
    //   created_at: '2025-01-30T10:15:30.123Z',
    //   created_by: 'admin',
    //   updated_at: '2025-01-30T12:20:45.456Z',
    //   updated_by: 'admin',
    //   is_active: true,
    //   id: '001',
    //   company_id: 'C-123',
    //   user_id: 'U-1001',
    //   employee_id: 'E-5001',
    //   department_id: 'D-2001',
    //   department_name: 'Marketing',
    //   email: 'john.doe@example.com',
    //   name: 'John Doe',
    //   phone: '+1-555-1234',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: true,
    //   campaign_ids: ['CMP-101', 'CMP-202'],
    //   team_ids: ['T-100', 'T-200'],
    //   first_donation_day: '05',
    //   last_donation_day: '20',
    //   last_donation_month: 'January',
    //   last_donation_year: '2025',
    //   total_amount: 500,
    //   last_donation_amount: 100,
    //   total_donation: 5,
    // },
    // {
    //   created_at: '2025-01-15T08:45:15.789Z',
    //   created_by: 'admin',
    //   updated_at: '2025-01-25T09:50:20.321Z',
    //   updated_by: 'admin',
    //   is_active: true,
    //   id: '002',
    //   company_id: 'C-456',
    //   user_id: 'U-2002',
    //   employee_id: 'E-6002',
    //   department_id: 'D-3002',
    //   department_name: 'Finance',
    //   email: 'jane.smith@example.com',
    //   name: 'Jane Smith',
    //   phone: '+1-555-5678',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: false,
    //   campaign_ids: ['CMP-303'],
    //   team_ids: ['T-300'],
    //   first_donation_day: '10',
    //   last_donation_day: '18',
    //   last_donation_month: 'December',
    //   last_donation_year: '2024',
    //   total_amount: 750,
    //   last_donation_amount: 250,
    //   total_donation: 3,
    // },
    // {
    //   created_at: '2025-02-05T14:30:10.654Z',
    //   created_by: 'admin',
    //   updated_at: '2025-02-10T16:45:22.987Z',
    //   updated_by: 'admin',
    //   is_active: false,
    //   id: '003',
    //   company_id: 'C-789',
    //   user_id: 'U-3003',
    //   employee_id: 'E-7003',
    //   department_id: 'D-4003',
    //   department_name: 'IT',
    //   email: 'alice.jones@example.com',
    //   name: 'Alice Jones',
    //   phone: '+1-555-9876',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: true,
    //   campaign_ids: ['CMP-404', 'CMP-505'],
    //   team_ids: ['T-400', 'T-500'],
    //   first_donation_day: '12',
    //   last_donation_day: '28',
    //   last_donation_month: 'November',
    //   last_donation_year: '2024',
    //   total_amount: 1200,
    //   last_donation_amount: 400,
    //   total_donation: 6,
    // },
    // {
    //   created_at: '2025-01-30T10:15:30.123Z',
    //   created_by: 'admin',
    //   updated_at: '2025-01-30T12:20:45.456Z',
    //   updated_by: 'admin',
    //   is_active: true,
    //   id: '001',
    //   company_id: 'C-123',
    //   user_id: 'U-1001',
    //   employee_id: 'E-5001',
    //   department_id: 'D-2001',
    //   department_name: 'Marketing',
    //   email: 'john.doe@example.com',
    //   name: 'John Doe',
    //   phone: '+1-555-1234',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: true,
    //   campaign_ids: ['CMP-101', 'CMP-202'],
    //   team_ids: ['T-100', 'T-200'],
    //   first_donation_day: '05',
    //   last_donation_day: '20',
    //   last_donation_month: 'January',
    //   last_donation_year: '2025',
    //   total_amount: 500,
    //   last_donation_amount: 100,
    //   total_donation: 5,
    // },
    // {
    //   created_at: '2025-01-15T08:45:15.789Z',
    //   created_by: 'admin',
    //   updated_at: '2025-01-25T09:50:20.321Z',
    //   updated_by: 'admin',
    //   is_active: true,
    //   id: '002',
    //   company_id: 'C-456',
    //   user_id: 'U-2002',
    //   employee_id: 'E-6002',
    //   department_id: 'D-3002',
    //   department_name: 'Finance',
    //   email: 'jane.smith@example.com',
    //   name: 'Jane Smith',
    //   phone: '+1-555-5678',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: false,
    //   campaign_ids: ['CMP-303'],
    //   team_ids: ['T-300'],
    //   first_donation_day: '10',
    //   last_donation_day: '18',
    //   last_donation_month: 'December',
    //   last_donation_year: '2024',
    //   total_amount: 750,
    //   last_donation_amount: 250,
    //   total_donation: 3,
    // },
    // {
    //   created_at: '2025-02-05T14:30:10.654Z',
    //   created_by: 'admin',
    //   updated_at: '2025-02-10T16:45:22.987Z',
    //   updated_by: 'admin',
    //   is_active: false,
    //   id: '003',
    //   company_id: 'C-789',
    //   user_id: 'U-3003',
    //   employee_id: 'E-7003',
    //   department_id: 'D-4003',
    //   department_name: 'IT',
    //   email: 'alice.jones@example.com',
    //   name: 'Alice Jones',
    //   phone: '+1-555-9876',
    //   profile_img: '{"url": "./assets/images/round_profile.png"}',
    //   is_shohay_user: true,
    //   campaign_ids: ['CMP-404', 'CMP-505'],
    //   team_ids: ['T-400', 'T-500'],
    //   first_donation_day: '12',
    //   last_donation_day: '28',
    //   last_donation_month: 'November',
    //   last_donation_year: '2024',
    //   total_amount: 1200,
    //   last_donation_amount: 400,
    //   total_donation: 6,
    // },
  ];

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly corporateCampaignService: CorporateCampaignService
  ) {
    this.companyId = this.localStorageService.getSessionUser().company_id;
  }
  
  ngOnInit(): void {
    this.getDonorLeaderBoard();
    this.slicingArray();
  }

  private getDonorLeaderBoard(): void {
    this.corporateCampaignService
      .getCorporateDonorLeaderboard(this.companyId)
      .subscribe((result: any) => {
        this.leaderBoard = result;
        // this.donorLeaderBoard = this.leaderBoard;
        this.slicedEmployeeLeaderboardList = this.leaderBoard.slice(0, 5);
        this.leaderBoard.map((item: any) => {
          item.profile_img = this.utilitiesService.jsonParse(item.profile_img);
        });
      });
  }

  public slicingArray() {
    
    this.slicedEmployeeTeam = this.employeeTeams.slice(0, 5);
  }

  public employeeLeaderboardPopupOpen() {
    this.employeeLeaderboardPopup = true;
  }

  public employeeTeamPopupOpen() {
    this.employeeTeamPopup = true;
  }
  
  public closeCreateEmailPopup() {
    this.employeeLeaderboardPopup = false;
    this.employeeTeamPopup = false;
  }

}
