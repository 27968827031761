<div class="main_div">
  <div class="breadcrumb">
    <span> Campaigns </span>
  </div>

  @if(campaignList?.length > 0){
  <div class="title_sec">Campaigns</div>

  <div class="slider">
    <ngb-carousel style="width: 100%">
      <ng-template ngbSlide *ngFor="let slide of campaignList; let i = index">
        <div class="slide_div">
          <div class="inner_half_slide">
            <div class="info_div">
              <div
                class="left_side"
                [ngStyle]="{
                  'background-image':
                    'url(' + campaignList[i]?.cover_img_vdo?.url + ')'
                }"
              ></div>
              <div class="right_side_outer">
                <div class="right_side_first">
                  <div class="ngo_name" style="margin-bottom: 16px">
                    {{ campaignList[i]?.title }}
                  </div>
                  <div class="organized_text" style="margin-bottom: 16px">
                    {{ campaignList[i]?.company_name }}
                    <span>
                      <img
                        loading="lazy"
                        alt="verified"
                        class="verified_icon"
                        src="./assets/images/icon/checkmark-badge.svg"
                      />
                    </span>
                  </div>
                  <div
                    class="primary-education-description"
                    style="margin-bottom: 32px"
                  >
                    {{ campaignList[i]?.description }}
                  </div>
                  <div class="donation_count" style="margin-bottom: 16px">
                    <div class="img_number">
                      <div class="img">
                        <img
                          class="arrow-right-alt-icon"
                          loading="lazy"
                          alt=""
                          src="../../../../../assets/images/icon/user-multiple.svg"
                        />
                      </div>
                      <div class="number">
                        {{ campaignList[i]?.donation_count }}
                      </div>
                    </div>

                    <div class="img_number">
                      <div class="img">
                        <img
                          class="arrow-right-alt-icon"
                          loading="lazy"
                          alt=""
                          src="../../../../../assets/images/icon/funds_used_icon_black.svg"
                        />
                      </div>
                      <div class="number">
                        Matching -
                        {{
                          campaignList[i]?.matching_percentage / 100 | percent
                        }}
                      </div>
                    </div>

                    <div class="img_number">
                      <div class="img">
                        <img
                          class="arrow-right-alt-icon"
                          loading="lazy"
                          alt=""
                          src="../../../../../assets/images/icon/time-01.svg"
                        />
                      </div>
                      <div class="number">
                        {{
                          campaignList[i]?.end_date
                            ? calculateDaysLeft(campaignList[i].end_date) +
                              " Days Left"
                            : "Ongoing"
                        }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <dx-progress-bar
                      class="progress_bar"
                      style="margin-bottom: 16px"
                      [min]="0"
                      [max]="campaignList[i]?.fundraising_target"
                      [value]="campaignList[i]?.total_fund_raised"
                      [showStatus]="false"
                      [hint]="
                        (campaignList[i]?.total_fund_raised /
                          campaignList[i]?.fundraising_target) *
                          100 | percent
                      "
                    ></dx-progress-bar>
                  </div>
                  <div class="donation_count" style="margin-bottom: 16px">
                    <div class="donation_number">
                      Total - ৳
                      {{ campaignList[i]?.total_fund_raised | number }}
                    </div>

                    <div class="donation_status" style="margin-bottom: 16px">
                      Target of ৳
                      {{ campaignList[i]?.fundraising_target | number }}
                    </div>
                  </div>
                </div>
                <div class="buttons">
                  <button
                    class="yellow-button-lg"
                    (click)="navigateToCampaign(slide)"
                  >
                    Donate Now
                  </button>
                  <button
                    class="blue-button-lg"
                    (click)="navigateToCampaign(slide)"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
    <div class="indicator_div"></div>
  </div>
  }

  <div class="active_donation">
    <div class="title_sec">My Active Donations</div>
    <div class="payment_cards">
      <div class="total_fund_raised" style="align-items: flex-end">
        <div class="title_amount">
          <div class="title_card">Total Donations</div>
          <div class="amount active">
            ৳ {{ dashboard?.total_donation | number }} <span>BDT</span>
          </div>
        </div>
        <div class="icon">
          <img
            src="../../../../../../assets/images/icon/money-bag.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="total_supporters" style="align-items: flex-end">
        <div class="title_amount">
          <div class="title_card">Matched Donations</div>
          <div class="amount active">
            ৳ {{ dashboard?.matched_donation | number }}
          </div>
        </div>
        <div class="icon">
          <img
            src="../../../../../../assets/images/icon/cash_cycle_icon.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="total_supporters" style="align-items: flex-end">
        <div class="title_amount">
          <div class="title_card">Your Donations</div>
          <div class="amount active">
            ৳ {{ dashboard?.my_donation | number }}
          </div>
        </div>
        <div class="icon">
          <img
            src="../../../../../../assets/images/icon/heart_hand_active.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
  </div>

  @if(donatedCampaigns?.length > 0){
  <div class="donated_campaign">
    <div class="title_sec mb-0">Campaigns You Have Donated</div>
    <div class="see_all_sub mb-3">
      <div class="title_card">Based On your Contribution</div>
      <div class="see_all">View all</div>
    </div>

    <div class="row">
      <div class="col-6" *ngFor="let campaign of donatedCampaigns">
        <div class="info_div info_div_donated">
          <div class="special_left_side">
            <div
              class="left_side"
              [ngStyle]="{
                'background-image': 'url(' + campaign?.cover_img_vdo?.url + ')'
              }"
              style="height: 70%; width: 100%"
            ></div>
            <div class="ngo_name donated_ngo_name">
              {{ campaign?.title }}
            </div>
            <div class="organized_text">
              {{ campaign?.company_name }}
              <span>
                <img
                  loading="lazy"
                  alt="verified"
                  class="verified_icon"
                  src="./assets/images/icon/checkmark-badge.svg"
                />
              </span>
            </div>
          </div>
          <div class="right_side">
            <div class="primary-education-description">
              {{ campaign?.description }}
            </div>
            <div class="donation_count">
              <div class="img_number">
                <div class="img">
                  <img
                    class="arrow-right-alt-icon"
                    loading="lazy"
                    alt=""
                    src="../../../../../assets/images/icon/user-multiple.svg"
                  />
                </div>
                <div class="number">
                  {{ campaign?.donation_count | number }}
                  Donations
                </div>
              </div>

              <div class="img_number">
                <div class="img">
                  <img
                    class="arrow-right-alt-icon"
                    loading="lazy"
                    alt=""
                    src="../../../../../assets/images/icon/time-01.svg"
                  />
                </div>
                <div class="number">
                  {{
                    campaign?.end_date
                      ? calculateDaysLeft(campaign?.end_date) + " Days Left"
                      : "Ongoing"
                  }}
                </div>
              </div>
            </div>

            <dx-progress-bar
              class="progress"
              [min]="0"
              [max]="campaign.fundraising_target"
              [value]="campaign.total_fund_raised"
              [showStatus]="false"
              [hint]="
                (campaign?.total_fund_raised / campaign?.fundraising_target) *
                  100 | percent
              "
            ></dx-progress-bar>

            <div class="d-flex justify-content-between w-100">
              <div class="donation_count fw-normal">
                Matching -
                {{ campaign?.matching_percentage }}%
              </div>
              <div class="donation_count funded">
                {{
                  (campaign?.total_fund_raised / campaign?.fundraising_target) *
                    100 | percent
                }}
                Funded
              </div>
            </div>

            <button class="yellow-button-lg" (click)="navigateToCampaign(campaign)">
              Donate Again
            </button>
            <button class="blue-button-lg" (click)="navigateToCampaign(campaign)">
              View Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  }
</div>
