import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { EmployeeCampaignDashboardComponent } from './employee-campaign-dashboard/employee-campaign-dashboard.component';
import { EmployeeCampaignDetailsComponent } from './employee-campaign-details/employee-campaign-details.component';
import { CorporateEmployeeDashboardComponent } from './corporate-employee-dashboard/corporate-employee-dashboard.component';
import { CorporateEmployeeLeaderboardComponent } from './corporate-employee-leaderboard/corporate-employee-leaderboard.component';
import { CorporateEmployeeProfileComponent } from './corporate-employee-profile/corporate-employee-profile.component';

const routes: Routes = [
  {
    path: '',
    //component: CorporateCampaignListComponent,
    redirectTo: '/employee/campaigns/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'campaigns/dashboard',
    component: EmployeeCampaignDashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'campaigns/view',
    component: EmployeeCampaignDetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-dashboard',
    component: CorporateEmployeeDashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-leaderboard',
    component: CorporateEmployeeLeaderboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'my-profile',
    component: CorporateEmployeeProfileComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployeeRoutingModule {}
