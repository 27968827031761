import { CorporateCampaignService } from './../../../shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CorporateProfileService } from 'src/app/shared/services/corporate/corporate-profile/corporate-profile.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-employee-campaign-dashboard',
  templateUrl: './employee-campaign-dashboard.component.html',
  styleUrl: './employee-campaign-dashboard.component.scss',
})
export class EmployeeCampaignDashboardComponent {
  private companyId: string =
    this.localStorageService.getSessionUser()?.company_id;
  public activeTab: number = 1;

  public dashboard: any;
  public campaignList: any[] = [];
  public donatedCampaigns: any[] = [];

  constructor(
    private readonly router: Router,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly corporateProfileService: CorporateProfileService,
    private readonly corporateCampaignService: CorporateCampaignService
  ) {}

  ngOnInit(): void {
    this.getDashboard();
    this.getAllCampaigns();
  }

  private getDashboard(): void {
    this.corporateProfileService
      .getEmployeeCampaignDashboard()
      .subscribe((result: any) => {
        this.dashboard = result;
        this.dashboard?.donated_campaign?.forEach((item) => {
          item.cover_img_vdo = JSON.parse(item?.cover_img_vdo);
        });
        this.donatedCampaigns = this.dashboard?.donated_campaign
          ?.sort((a, b) => a.updated_at - b.updated_at)
          .slice(0, 2)
          .map((item) => ({
            ...item,
            cover_img_vdo: this.utilitiesService.jsonParse(item?.cover_img_vdo),
          }));
      });
  }

  private getAllCampaigns(): void {
    this.corporateCampaignService
      .getCorporateCampaignsByCompany(this.companyId)
      .subscribe({
        next: (result: any) => {
          this.campaignList = result;
        },
      });
  }

  // public employeeCampaignDashboard() {
  //   this.employeeService.getEmployeeCampaignSummary().subscribe({
  //     next: (result: any) => {
  //       this.employeeCampaignList = result;
  //     },
  //   });
  // }

  public navigateToCampaign(campaign: any) {
    this.router.navigate(['employee/campaigns/view'], {
      queryParams: { id: campaign?.id },
    });
  }

  public calculateDaysLeft(endDate: string): number {
    const end = new Date(endDate).getTime();
    const now = new Date().getTime();
    if (end >= now) {
      return Math.ceil((end - now) / (1000 * 3600 * 24));
    } else {
      return 0;
    }
  }
}
