export class CorporateDonationModel {
    name: string = null;
    email: string = null;
    amount: number = 0;
    matched_donation: number = 0;
    transaction_fees: number = 0;
    donor_id: string = null;
    user_id: string = null;
    company_id: string = null;
    campaign_id: string = null;
    campaign_title: string = null;
    is_shohay_user: boolean = true;

    transaction_id: string = null;
    fundraiser_id: string = null;
    team_id: string = null;

    comment: string = null;
    whatsapp_no: string = null;
    is_hidden_name: boolean = false;
    is_hidden_comment: boolean = false;
    can_contact: boolean = true;
    is_hidden_name_and_amount: boolean = false;
    is_featured_donation: boolean = true;
    is_video_call_active: boolean = false;
    donation_day: string = null;
    donation_month: string = null;
    last_donation_date: string = null;
  

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}