import { Component } from '@angular/core';
import { CorporateCampaignService } from 'src/app/shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { EmployeeService } from 'src/app/shared/services/corporate/employee/employee.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { CorporateEmployeeProfileModel } from 'src/app/shared/model/corporate/corporate-employee-profile.model';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { CorporateProfileService } from 'src/app/shared/services/corporate/corporate-profile/corporate-profile.service';
@Component({
  selector: 'app-corporate-employee-profile',
  templateUrl: './corporate-employee-profile.component.html',
  styleUrl: './corporate-employee-profile.component.scss',
})
export class CorporateEmployeeProfileComponent {
  public activeTabIndex = 0;
  public editMode = false;
  public showDonations: boolean = false;
  public showDeletePopup: boolean = false;
  public showConfirmDeletePopup: boolean = false;

  public showDeactivatePopup: boolean = false;
  public isNewPasswordVisible = false;
  public progress = 25;

  public profileDetails: [] = [];
  public employeeProfileModel = new CorporateEmployeeProfileModel();

  public toggleStates = {
    approvalSwitchButton: false,
  };

  radioOptions = [
    {
      text: '',
      description:
        'Deactivating your account is temporary. Your account and profile will be deactivated and your name will be removed from most campaigns.',
      value: 'deactivate',
    },
  ];

  public profile: any;
  public updateBody: CorporateEmployeeProfileModel =
    new CorporateEmployeeProfileModel();
  public departmentList: any[] = [];

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly employeeService: EmployeeService,
    private readonly corporateProfileService: CorporateProfileService
  ) {}

  ngOnInit(): void {
    this.getEmployeeDetails();
    this.getDepartments();
  }

  public onTabChange(event: any) {
    this.activeTabIndex = event.component.option('selectedIndex');
  }

  public toggleEditMode() {
    this.editMode = !this.editMode;
  }

  public toggleNewPasswordVisibility = () => {
    this.isNewPasswordVisible = !this.isNewPasswordVisible;
  };

  public onToggleChange(toggleKey: string, value: boolean) {
    this.toggleStates[toggleKey] = value;
    console.log('Updated Toggle States:', this.toggleStates);
  }

  public getEmployeeDetails() {
    this.employeeService.getEmployeeDetails().subscribe({
      next: (result) => {
        this.profile = { ...this.profile, ...result };
        this.updateBody = new CorporateEmployeeProfileModel(this.profile);
        this.getProfile();
      },
    });
  }

  private getProfile(): void {
    this.employeeService.getEmployeeProfile().subscribe((result: any) => {
      // this.companyId = result.id;
      this.profile = { ...this.profile, ...result };
      this.updateBody = new CorporateEmployeeProfileModel(this.profile);
    });
  }

  public updateProfile(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.employeeService
        .updateCorporateEmployeeProfile(this.updateBody, this.profile.id)
        .subscribe((result: any) => {
          this.editMode = !this.editMode;
        });
    }
  }

  private getDepartments(): void {
    const company_id = this.localStorageService.getSessionUser().company_id;
    this.corporateProfileService
      .getDepartments(company_id)
      .subscribe((result: any) => {
        this.departmentList = result.map((list)=>{
          return list.department_name;
        });
      });
  }

  public onDepartmentChange(e: any) {
    this.updateBody.department_name = e.value;
  }

  // public updateProfile(): void {
  //   const { isValid } = ValidationEngine.validateGroup('validationGrp');
  //   if (isValid) {
  //     this.corporateProfileService
  //       .updateCorporateProfile(this.companyId, this.updateBody)
  //       .subscribe((result: any) => {
  //         this.editMode = !this.editMode;
  //       });
  //   }
  // }
}
