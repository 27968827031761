<div class="main_div">
  <div style="margin-bottom: 32px">Employee Leaderboard</div>
  <div class="main_body">
    <div class="main_body_title_icon">
      <div class="main_body_title">Employee Leaderboard</div>
      <div (click)="employeeLeaderboardPopupOpen()" class="see_all cursor">
        View All
      </div>
    </div>
    <div class="grid_component">
      <div class="top_donor_div pr-0">
        <div class="donor_div">
          <dx-data-grid
            class="chart_div data_grid"
            [dataSource]="slicedEmployeeLeaderboardList"
            [showBorders]="false"
            [showColumnLines]="false"
            [showRowLines]="false"
            [allowColumnResizing]="true"
            columnResizingMode="widget"
            [focusedRowEnabled]="false"
            [rowAlternationEnabled]="false"
            rowTemplate="rowTemplateName"
            [wordWrapEnabled]="true"
            [columnAutoWidth]="false"
          >
            <dxi-column
              [width]="'90px'"
              caption="Rank"
              dataField="id"
              style="text-align: left"
            ></dxi-column>
            <dxi-column
              [width]="'23%'"
              caption="Employee Name"
              dataField="employee_name"
            ></dxi-column>
            <dxi-column
              [width]="'23%'"
              caption="Department"
              dataField="department"
            >
            </dxi-column>
            <dxi-column
              [width]="'23%'"
              caption="Contribution"
              dataField="contribution"
            >
            </dxi-column>
            <dxi-column
              [width]="'23%'"
              caption="Campaign Participated"
              dataField="Campaigns_Participate"
            >
            </dxi-column>

            <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
              <tr class="custom-row">
                <td style="text-align: left">
                  <div class="employee_rank">
                    @if(row?.rowIndex < 3){
                    <img
                      loading="lazy"
                      [src]="
                        './assets/images/icon/trophy_' + row?.rowIndex + '.svg'
                      "
                      alt="rank"
                    />
                    }@else {
                    <div class="trophy_img_" style="margin-left: 8px;">{{ row?.rowIndex + 1 }}</div>
                    }
                  </div>
                </td>
                <td>
                  <div class="combine">
                    <div class="employee_profile">
                      <img
                        loading="lazy"
                        [src]="row?.data?.profile_img?.url || './assets/images/round_profile.png'"
                        alt="PROFILE"
                      />
                    </div>
                    <div class="normal_data" style="padding-top: 5px">
                      {{ row?.data?.name }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="normal_data">{{ row.data?.department_name }}</div>
                </td>
                <td>
                  <div class="normal_data">৳ {{ row.data?.total_amount | number }}</div>
                </td>
                <td>
                  <div class="combine">
                    <div class="employee_rank multiple_icon">
                      <img
                        loading="lazy"
                        src="./assets/images/icon/mic_announcement.svg"
                        alt="rank"
                      />
                    </div>
                    <div class="normal_data" style="color: #0054a6">
                      ({{ row.data?.campaign_ids?.length | number }})
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </dx-data-grid>
        </div>
      </div>
    </div>
    <div class="main_body_title_icon">
      <div class="main_body_title">Top Employee Teams</div>
      <div (click)="employeeTeamPopupOpen()" class="see_all cursor">
        View All
      </div>
    </div>
    <div class="grid_component">
      <div class="top_donor_div pr-0">
        <div class="donor_div">
          <dx-data-grid
            class="chart_div data_grid"
            [dataSource]="slicedEmployeeTeam"
            [showBorders]="false"
            [showColumnLines]="false"
            [showRowLines]="false"
            [allowColumnResizing]="true"
            columnResizingMode="widget"
            [focusedRowEnabled]="false"
            [rowAlternationEnabled]="false"
            rowTemplate="rowTemplateName"
            [wordWrapEnabled]="true"
            [columnAutoWidth]="false"
          >
            <dxi-column
              [width]="'90px'"
              caption="Rank"
              dataField="id"
            ></dxi-column>
            <dxi-column
              [width]="'35%'"
              caption="Employee Teams"
              dataField="activity_Type"
            >
            </dxi-column>
            <dxi-column
              [width]="'30%'"
              caption="Contributions"
              dataField="description"
            >
            </dxi-column>
            <dxi-column
              [width]="'30%'"
              caption="Campaign Participated"
              dataField="Campaigns_Participate"
            >
            </dxi-column>

            <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
              <tr class="custom-row">
                <td>
                  <div class="employee_rank">
                    @if(row?.data?.id === 1){
                    <img
                      loading="lazy"
                      [src]="'./assets/images/icon/trophy_0.svg'"
                      alt="rank"
                    />
                    }@else if(row?.data?.id === 2){
                    <img
                      loading="lazy"
                      [src]="'./assets/images/icon/trophy_1.svg'"
                      alt="rank"
                    />
                    }@else if(row?.data?.id === 3){
                    <img
                      loading="lazy"
                      [src]="'./assets/images/icon/trophy_2.svg'"
                      alt="rank"
                    />
                    }@else {
                    <div class="trophy_img_">{{ row?.data?.id }}</div>
                    }
                  </div>
                </td>
                <td>
                  <div class="combine">
                    <div class="employee_profile">
                      <img
                        loading="lazy"
                        src="{{ row?.data?.profile }}"
                        alt="PROFILE"
                      />
                    </div>
                    <div class="normal_data" style="padding-top: 5px">
                      {{ row?.data?.employee_name }}
                    </div>
                  </div>
                </td>

                <td>
                  <div class="normal_data">৳{{ row.data?.contribution }}</div>
                </td>

                <td>
                  <div class="combine">
                    <div class="employee_rank multiple_icon">
                      <img
                        loading="lazy"
                        src="./assets/images/icon/person-logo-icon.svg"
                        alt="rank"
                      />
                    </div>
                    <div class="normal_data" style="color: #0054a6">
                      ({{ row.data?.Campaigns_Participate }})
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-popup
  [width]="'90vw'"
  [height]="'90vh'"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="employeeLeaderboardPopup"
  [showTitle]="false"
>
  <div class="root">
    <div
      class="cross_button_div_"
      style="margin-right: 20px; text-align: right; width: 100%"
    >
      <button class="cross_button" (click)="closeCreateEmailPopup()">
        <img
          src="../../../../../assets/images/icon/x-circle.svg"
          alt="cross"
          loading="lazy"
          title="close"
        />
      </button>
    </div>
    <div style="margin-bottom: 36px" class="popUpTitle">
      Employee Leaderboard
    </div>
    <div class="donor_div" style="border-bottom: 8px">
      <dx-data-grid
        class="chart_div data_grid"
        [dataSource]="leaderBoard"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        rowTemplate="rowTemplateName"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="false"
      >
        <dxi-column
          [width]="'90px'"
          style="text-align: left"
          caption="Rank"
          dataField="id"
        ></dxi-column>
        <dxi-column
          [width]="'25%'"
          caption="Employee Name"
          dataField="employee_name"
        ></dxi-column>
        <dxi-column [width]="'20%'" caption="Department" dataField="department">
        </dxi-column>
        <dxi-column
          [width]="'25%'"
          caption="Contribution"
          dataField="contribution"
        >
        </dxi-column>
        <dxi-column
          [width]="'25%'"
          caption="Campaign Participated"
          dataField="Campaigns_Participate"
        >
        </dxi-column>

        <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
          <tr class="custom-row">
            <td style="text-align: left">
              <div class="employee_rank">
                @if(row?.rowIndex < 3){
                <img
                  loading="lazy"
                  [src]="
                    './assets/images/icon/trophy_' + row?.rowIndex + '.svg'
                  "
                  alt="rank"
                />
                }@else {
                <div class="trophy_img_">{{ row?.rowIndex + 1 }}</div>
                }
              </div>
            </td>
            <td>
              <div class="combine">
                <div class="employee_profile">
                  <img
                    loading="lazy"
                    [src]="row?.data?.profile_img?.url || './assets/images/round_profile.png'"
                    alt="PROFILE"
                  />
                </div>
                <div class="normal_data" style="padding-top: 5px">
                  {{ row?.data?.name }}
                </div>
              </div>
            </td>
            <td>
              <div class="normal_data">{{ row.data?.department_name }}</div>
            </td>
            <td>
              <div class="normal_data">৳ {{ row.data?.total_amount | number }}</div>
            </td>
            <td>
              <div class="combine">
                <div class="employee_rank multiple_icon">
                  <img
                    loading="lazy"
                    src="./assets/images/icon/mic_announcement.svg"
                    alt="rank"
                  />
                </div>
                <div class="normal_data" style="color: #0054a6">
                  ({{ row.data?.campaign_ids?.length | number }})
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="'90vw'"
  [height]="'90vh'"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="employeeTeamPopup"
  [showTitle]="false"
>
  <div class="root">
    <div
      class="cross_button_div_"
      style="margin-right: 20px; text-align: right; width: 100%"
    >
      <button class="cross_button" (click)="closeCreateEmailPopup()">
        <img
          src="../../../../../assets/images/icon/x-circle.svg"
          alt="cross"
          loading="lazy"
          title="close"
        />
      </button>
    </div>
    <div style="margin-bottom: 36px" class="popUpTitle">Top Employee Teams</div>
    <div class="donor_div" style="margin-bottom: 8px;">
      <dx-data-grid
        class="chart_div data_grid"
        [dataSource]="employeeTeams"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        rowTemplate="rowTemplateName"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="false"
      >
        <dxi-column [width]="'20%'" caption="Rank" dataField="id"></dxi-column>
        <dxi-column
          [width]="'25%'"
          caption="Employee Teams"
          dataField="activity_Type"
        >
        </dxi-column>
        <dxi-column
          [width]="'25%'"
          caption="Contributions"
          dataField="description"
        >
        </dxi-column>
        <dxi-column
          [width]="'25%'"
          caption="Campaign Participated"
          dataField="Campaigns_Participate"
        >
        </dxi-column>

        <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
          <tr class="custom-row">
            <td>
              <div class="employee_rank">
                @if(row?.data?.id === 1){
                <img
                  loading="lazy"
                  [src]="'./assets/images/icon/trophy_0.svg'"
                  alt="rank"
                />
                }@else if(row?.data?.id === 2){
                <img
                  loading="lazy"
                  [src]="'./assets/images/icon/trophy_1.svg'"
                  alt="rank"
                />
                }@else if(row?.data?.id === 3){
                <img
                  loading="lazy"
                  [src]="'./assets/images/icon/trophy_2.svg'"
                  alt="rank"
                />
                }@else {
                <div class="trophy_img_">{{ row?.data?.id }}</div>
                }
              </div>
            </td>
            <td>
              <div class="combine">
                <div class="employee_profile">
                  <img
                    loading="lazy"
                    src="{{ row?.data?.profile }}"
                    alt="PROFILE"
                  />
                </div>
                <div class="normal_data" style="padding-top: 5px">
                  {{ row?.data?.employee_name }}
                </div>
              </div>
            </td>

            <td>
              <div class="normal_data">৳{{ row.data?.contribution }}</div>
            </td>

            <td>
              <div class="combine">
                <div class="employee_rank multiple_icon">
                  <img
                    loading="lazy"
                    src="./assets/images/icon/person-logo-icon.svg"
                    alt="rank"
                  />
                </div>
                <div class="normal_data" style="color: #0054a6">
                  ({{ row.data?.Campaigns_Participate }})
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>
