import { CorporateCampaignService } from 'src/app/shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { RichTextService } from 'src/app/shared/services/rich-text/rich-text.service';
import { CorporateDonationModel } from 'src/app/shared/model/corporate/corporate-donation.model';
import { ReceiptModel } from 'src/app/shared/model/receipt.model';
import { CommonSharedServiceService } from 'src/app/shared/shared-services/common-shared-service/common-shared-service.service';
import { PaymentTypeEnum } from 'src/app/shared/enum/payment-type.enum';

@Component({
  selector: 'app-employee-campaign-details',
  templateUrl: './employee-campaign-details.component.html',
  styleUrl: './employee-campaign-details.component.scss',
})
export class EmployeeCampaignDetailsComponent implements OnInit {
  public suggestedFund: any = [
    { amount: 200, isSelected: false },
    { amount: 500, isSelected: false },
    { amount: 1000, isSelected: false },
    { amount: 10000, isSelected: false },
    { amount: 20000, isSelected: false },
    { amount: 50000, isSelected: false },
  ];
  public url = '';
  public dayLeft: number | string;

  public openThankYouPopup: boolean = false;
  public donationPopup = false;
  //public openPaymentReceipt = false;

  public sessionUser: any = this.localStorageService.getSessionUser();
  public campaignId: string;
  public campaign: any;

  public coverFees: boolean = true;
  public selectedAmount: number = 10;
  public donationBody: CorporateDonationModel = new CorporateDonationModel(
    this.sessionUser
  );

  public receipt: ReceiptModel = new ReceiptModel();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly richTextService: RichTextService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly sharedService: CommonSharedServiceService,
    private readonly corporateCampaignService: CorporateCampaignService
  ) {
    this.sharedService.updateReceiptPopupState(false);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.campaignId = params['id'];
      if (this.campaignId) {
        this.getCampaignDetails();
      } else {
        //this.router.navigate(['/employee/campaigns/dashboard']);
      }
    });
  }

  private getCampaignDetails(): void {
    this.corporateCampaignService
      .getCorporateCampaignDetails(this.campaignId)
      .subscribe((result: any) => {
        result.cover_img_vdo = JSON.parse(result.cover_img_vdo);
        result.donation_summary = JSON.parse(result.donation_summary);

        result.story_rich_text = this.richTextService.decodeHtml(
          result.story_rich_text
        );
        const element = document.getElementById('campaign_story');
        element.innerHTML = result.story_rich_text;

        this.getDaysLeft(result?.end_date);

        this.campaign = result;
      });
  }

  public selectAmount(index: number): void {
    this.suggestedFund.forEach((item) => (item.isSelected = false));
    this.suggestedFund[index].isSelected = true;
    this.selectedAmount = this.suggestedFund[index].amount;
  }

  public donate(): void {
    this.donationBody.amount = this.coverFees
      ? this.selectedAmount
      : this.selectedAmount - this.selectedAmount * 0.14;
    this.donationBody.transaction_fees = this.selectedAmount * 0.14;
    this.donationBody.matched_donation =
      this.donationBody.amount * (this.campaign?.matching_percentage / 100);

    this.donationBody.campaign_id = this.campaign.id;
    this.donationBody.campaign_title = this.campaign.title;
    this.donationBody.user_id = this.sessionUser.id;
    this.makeDonation();
  }

  public makeDonation(): void {
    this.corporateCampaignService
      .createDonate(this.donationBody)
      .subscribe((result: any) => {
        this.donationPopup = false;
        this.url = this.router.url;
        this.openThankYouPopup = true;
      });
  }

  public openPaymentReceiptPopup() {
    this.openThankYouPopup = false;
    this.receipt = {
      invoice_no: '71NXMA9X',
      invoice_to: this.donationBody?.name,
      invoice_date: new Date(),
      label2Name: 'Company',
      label2Value: this.campaign?.company_name,
      payment_method: 'Card',
      paid_to: '',
      col1Name: 'Campaign',
      col1Value: this.donationBody?.campaign_title,
      col2Name: 'Your Donation',
      col2Value: this.donationBody?.amount,
      col3Name: 'Matched Amount',
      col3Value: this.donationBody?.matched_donation,
      total_amount:
        this.donationBody?.amount + this.donationBody?.matched_donation,
    };
    this.sharedService.updateReceiptPopupState(true);
  }

  public copyText(): void {
    navigator.clipboard.writeText(this.url).then(() => {
      this.utilitiesService.showSwalWithToast(
        `URL Copied`,
        `You copied the url`,
        'success'
      );
    });
  }
  
  public getDaysLeft(endDate: string): number {
    const end = new Date(endDate).getTime();
    const now = new Date().getTime();
    if (end >= now) {
      return Math.ceil((end - now) / (1000 * 3600 * 24));
    } else {
      return 0;
    }
  }

  public shareOnSocialMedia(socialMedia: string) {
    let shareUrl = '';

    switch (socialMedia) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          this.url
        )}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          this.url
        )}`;
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          this.url
        )}`;
        break;
      case 'twitter':
        shareUrl = `https://x.com/intent/tweet?url=${encodeURIComponent(
          this.url
        )}`;
        break;
      default:
        return;
    }
    window.open(shareUrl, '_blank');
  }
}
