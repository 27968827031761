<div class="main_div">
  <div style="margin-bottom: 32px">Employee Dashboard</div>
  <div class="main_body">
    <div class="main_body_title">Employee Dashboard</div>

    <div class="overview_carts">
      <div *ngFor="let carts of rowData" class="carts">
        <div class="upper_part">
          <div class="left_side">
            <!-- <div class="carts_title">{{ carts.carts_name }}</div> -->
            <div class="carts_description">{{ carts.carts_name }}</div>
          </div>
        </div>
        <div class="upper_part">
          <div class="total_amount">
            <span style="color: #0054a6" *ngIf="carts.isAmount">৳</span>
            {{ carts.calculated_data | number }}
          </div>
          <div class="right_side">
            <div class="carts_img">
              <img loading="lazy" [src]="carts.carts_icon" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="see_all_sub">
      <div class="main_body_title">Donation History</div>
      <div class="see_all cursor">See all</div>
    </div>
    <div class="donor_div">
      <dx-data-grid
        class="chart_div data_grid"
        [dataSource]="activityList"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        rowTemplate="rowTemplateName"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="false"
      >
        <dxi-column
          [width]="'15%'"
          caption="Date & Time"
          dataField="Date_Time"
        ></dxi-column>
        <dxi-column
          [width]="'30%'"
          caption="Campaign"
          dataField="activity_Type"
        >
        </dxi-column>
        <dxi-column [width]="'12%'" caption="Donated" dataField="description">
        </dxi-column>
        <dxi-column [width]="'12%'" caption="Matched" dataField="matched_amount">
        </dxi-column>
        <dxi-column [width]="'14%'" caption="Status" dataField="status">
        </dxi-column>
        <dxi-column [width]="'17%'" caption="Action" dataField="action">
        </dxi-column>

        <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
          <tr class="custom-row">
            <td>
              <div class="normal_data">
                {{ row?.data?.Date_Time }}
              </div>
              <div class="normal_time">
                {{ row?.data?.time }}
              </div>
              <!-- <div class="left_side_property" style="margin: 12px">
                    <div class="name_amount">
                      <div class="name">{{ row?.data?.comments }}</div>
                    </div>
                  </div>
                  {{ row?.data?.created_at }} -->
            </td>
            <td>
              <div class="normal_data name_colored">
                {{ row.data?.Campaign }}
              </div>
            </td>
            <td>
              <div class="normal_data">৳{{ row.data?.donated }}</div>
            </td>
            <td>
              <div class="normal_data">৳{{ row.data?.matched }}</div>
            </td>
            <td>
              <div class="normal_data">
                {{ row.data?.status }}
              </div>
            </td>
            <td class="button_td">
              <dx-button
                (click)="showReceiptPopup(row?.data)"
                class="blue-button-lg"
              >
                {{ row.data?.Action }}
              </dx-button>
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>

    <div class="see_all_sub">
      <div class="main_body_title">Ongoing Campaigns</div>
      <div class="see_all cursor">See all</div>
    </div>

    <div class="donated_campaign">
      <div *ngFor="let campaigns of donatedCampaigns" class="slide_div donated">
        <div class="inner_half_slide">
          <div class="info_div cursor info_div_donated">
            <div class="special_left_side">
              <div
                class="left_side"
                [ngStyle]="{
                  'background-image': 'url(' + campaigns.url + ')'
                }"
                style="height: 70%; width: 100%"
              >
                <div class="yellow_title_div">
                  <div class="yellow_title">Donate</div>
                </div>
              </div>
              <div class="ngo_name donated_ngo_name">
                {{ campaigns.title }}
              </div>
              <div class="organized_text">
                <div class="normal_data">
                  Donated - ৳ {{ campaigns.donated_amount }}
                </div>
                <div class="normal_data">
                  Matched - ৳ {{ campaigns.matched_amount }}
                </div>
              </div>
            </div>
            <div class="right_side">
              <!-- <div>
                  <dx-progress-bar
                    class="progress_bar"
                    [min]="0"
                    [max]="1000"
                    [value]="100"
                    [showStatus]="false"
                  ></dx-progress-bar>
                </div>
                <div class="campaign_target">৳{{ 899900 }}</div> -->
              <div class="primary-education-description">
                {{ campaigns.description }}
              </div>
              <div class="donation_count">
                <div class="img_number">
                  <div class="img">
                    <img
                      class="arrow-right-alt-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/user-multiple.svg"
                    />
                  </div>
                  <div class="number">
                    {{ campaigns.donation_count }} Donations
                  </div>
                </div>

                <div class="img_number">
                  <div class="img">
                    <img
                      class="arrow-right-alt-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/time-01.svg"
                    />
                  </div>
                  <div class="number">{{ campaigns.days_left }} Days Left</div>
                </div>
              </div>

              <!-- <dx-progress-bar
                class="progress"
                [min]="0"
                [max]="100"
                [value]="50"
                [showStatus]="false"
              ></dx-progress-bar> -->
              <dx-progress-bar
                class="progress"
                [min]="0"
                [max]="campaigns?.fundraising_target"
                [value]="campaigns?.total_fund_raised"
                [showStatus]="false"
                [hint]="
                  (campaigns?.total_fund_raised / campaigns?.fundraising_target) *
                    100 +
                  '%'
                "
              ></dx-progress-bar>
              <div class="donation_count funded">
                {{ campaigns.funded_percentage }}% Funded
              </div>
              <button (click)="navigateToCampaign(campaigns)" class="yellow-button-lg">Donate Again</button>
              <button (click)="navigateToCampaign(campaigns)" class="blue-button-lg">View Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <dx-popup
  [width]="'70vw'"
  [height]="'90vh'"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="receiptDetails"
  [showTitle]="false"
>
  <div class="root">
    <div
      class="cross_button_div_"
      style="margin-right: 20px; text-align: right; width: 100%"
    >
      <button class="cross_button" (click)="closeCreateEmailPopup()">
        <img
          src="../../../../../assets/images/icon/x-circle.svg"
          alt="cross"
          loading="lazy"
          title="close"
        />
      </button>
    </div>
    <div class="logo_title">
      <img src="./assets/images/logo/shohay-updated-logo  .svg" alt="" />
      <div class="pop_up_title">Payment Receipt</div>
    </div>
    <div class="root_body"> 
      <div class="invoice">Invoice to:</div>
      <div class="right_side_pop">
        <div class="title_answer">
          <div class="root_body_title">Name:</div>
          <div class="root_body_answer">Shohaynur Rahman</div>
        </div>
        <div class="title_answer">
          <div class="root_body_title">Company:</div>
          <div class="root_body_answer">Bengal Mobile QA Solution</div>
        </div>
        <div class="title_answer">
          <div class="root_body_title">Date:</div>
          <div class="root_body_answer">Monday, 30 June 2024</div>
        </div>
        <div class="title_answer">
          <div class="root_body_title">Payment Method:</div>
          <dx-button class="yellow-button-sm">Card</dx-button>
        </div>
      </div>
    </div>
    <div class="underline"></div>

    <div class="donor_div" style="width: 90%; margin-inline: auto">
      <dx-data-grid
        class="chart_div data_grid"
        [dataSource]="donorList"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        rowTemplate="rowTemplateName"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="false"
      >
        <dxi-column
          [width]="'30%'"
          caption="Organizer"
          dataField="organizer"
        ></dxi-column>
        <dxi-column [width]="'30%'" caption="Campaign" dataField="campaign">
        </dxi-column>
        <dxi-column
          [width]="'20%'"
          caption="Your Donation"
          dataField="description"
        >
        </dxi-column>
        <dxi-column [width]="'20%'" caption="Matched Amount" dataField="user">
        </dxi-column>

        <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
          <tr class="custom-row">
            <td>
              <div class="normal_data name_colored">
                {{ row?.data?.organizer }}
              </div>
            </td>
            <td>
              <div class="normal_data">
                {{ row.data?.campaign }}
              </div>
            </td>
            <td>
              <div class="normal_data">৳{{ row.data?.donation }}</div>
            </td>
            <td>
              <div class="normal_data">৳{{ row.data?.matched_amount }}</div>
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>

    <div class="last_stage">
      <div class="right_stage_child">
        <div class="total">Total</div>
        <div class="mount">80000</div>
      </div>
    </div>
  </div>
</dx-popup> -->

<app-receipts [receipt]="receiptObject"></app-receipts>
