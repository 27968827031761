import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from 'rxjs/internal/scheduler/Action';
import { ReceiptModel } from 'src/app/shared/model/receipt.model';
import { CorporateCampaignService } from 'src/app/shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { EmployeeService } from 'src/app/shared/services/corporate/employee/employee.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { CommonSharedServiceService } from 'src/app/shared/shared-services/common-shared-service/common-shared-service.service';

@Component({
  selector: 'app-corporate-employee-dashboard',
  templateUrl: './corporate-employee-dashboard.component.html',
  styleUrl: './corporate-employee-dashboard.component.scss',
})
export class CorporateEmployeeDashboardComponent {
  public rowData = [
    {
      carts_icon: './assets/images/icon/donate_hand_icon.svg',
      carts_name: 'Your Donation',
      isAmount: true,
      calculated_data: '5000',
    },
    {
      carts_icon: './assets/images/icon/cash-back.svg',
      carts_name: 'Matched Donation',
      isAmount: true,
      calculated_data: '2500',
    },
    {
      carts_icon: './assets/images/icon/rank-icon.svg',
      carts_name: 'Your Rank',
      isAmount: false,
      calculated_data: '0',
    },
  ];

  public activityList: any = [
    {
      Date_Time: '03 June 2025',
      time: '11.50 PM',
      Campaign: 'Emergency Flood  Relief campaign launched',
      donated: '1000',
      matched: '500',
      status: 'Completed',
      Action: 'View Receipt',
    },
    {
      Date_Time: '03 June 2025',
      time: '11.50 PM',
      Campaign: 'Emergency Flood  Relief campaign launched',
      donated: '1000',
      matched: '500',
      status: 'Completed',
      Action: 'View Receipt',
    },
    {
      Date_Time: '03 June 2025',
      time: '11.50 PM',
      Campaign: 'Emergency Flood  Relief campaign launched',
      donated: '1000',
      matched: '500',
      status: 'Completed',
      Action: 'View Receipt',
    },
    {
      Date_Time: '03 June 2025',
      time: '11.50 PM',
      Campaign: 'Emergency Flood  Relief campaign launched',
      donated: '1000',
      matched: '500',
      status: 'Completed',
      Action: 'View Receipt',
    },
    {
      Date_Time: '03 June 2025',
      time: '11.50 PM',
      Campaign: 'Emergency Flood  Relief campaign launched',
      donated: '1000',
      matched: '500',
      status: 'Completed',
      Action: 'View Receipt',
    },
  ];

  // public donorList: any = [
  //   {
  //     organizer: 'Bengal Mobile QA Solution',
  //     campaign: 'Youth Empowerment Workshop',
  //     donation: '5000',
  //     matched_amount: '2000',
  //   },
  // ];
  // public receiptDetails: boolean = true;

  public donatedCampaigns: any = [
    // {
    //   title: 'Emergency Flood Relief',
    //   donated_amount: '1000',
    //   matched_amount: '500',
    //   description:
    //     'The special assistant, who enjoys the authority and status of a state minister, said the government is not concerned about money and the injured would be taken wherever...',
    //   total_donation: '5000',
    //   days_left: '5',
    //   funded_percentage: '50',
    // },
    // {
    //   title: 'Emergency Flood Relief',
    //   donated_amount: '1000',
    //   matched_amount: '500',
    //   description:
    //     'The special assistant, who enjoys the authority and status of a state minister, said the government is not concerned about money and the injured would be taken wherever...',
    //   total_donation: '5000',
    //   days_left: '5',
    //   funded_percentage: '50',
    // },
  ];

  public receiptObject: ReceiptModel = new ReceiptModel();
  private companyId: string = null;

  constructor(
    private readonly router: Router,
    private readonly sharedService: CommonSharedServiceService,
    private readonly employeeService: EmployeeService,
    private readonly localStorageService: LocalStorageService,
    private readonly corporateCampaignService: CorporateCampaignService
  ) {
    this.sharedService.updateReceiptPopupState(false);
    this.companyId = this.localStorageService.getSessionUser().company_id;
  }

  ngOnInit(): void {
    this.getEmployeeDashboard();
    this.getAllCampaign();
    // this.getEmployeeDetails();
  }

  private getAllCampaign() {
    this.corporateCampaignService
      .getCorporateCampaignsByCompany(this.companyId)
      .subscribe({
        next: (result) => {
          this.donatedCampaigns = result.slice(0, 2).map((campaign) => ({
            title: campaign.title,
            donated_amount: (campaign.total_fund_raised || 0).toString(),
            matched_amount: (campaign.matching_budget || 0).toString(),
            description: campaign.description || 'No description available.',
            total_donation: campaign.fundraising_target.toString(),
            days_left: this.calculateDaysLeft(campaign.end_date),
            funded_percentage: (
              (campaign.total_fund_raised / campaign.fundraising_target) *
              100
            ).toFixed(2), 
            total_fund_raised: campaign.total_fund_raised,
            fundraising_target: campaign.fundraising_target,
            url: campaign.cover_img_vdo.url,
            id: campaign.id,
            donation_count: campaign.donation_count,
            status: campaign.status
          }));
        },
      });
  }

  public getEmployeeDashboard() {
    this.employeeService.getEmployeeDashboard().subscribe({
      next: (result) => {
        this.rowData[0].calculated_data = result.my_donation;
        this.rowData[1].calculated_data = result.matched_donation;
        this.activityList = result.donated_history.map((donation: any) => ({
          Date_Time: new Date(donation.donation_day).toLocaleDateString(
            'en-US',
            {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }
          ),
          time: new Date(donation.created_at).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }),
          Campaign: donation.campaign_title,
          donated: donation.amount.toString(),
          matched: donation.matched_donation.toString(),
          status: donation.status,
          Action: 'View Receipt',
          name: donation.name
        }));
      },
    });
  }

  // public getEmployeeDetails() {
  //   this.employeeService.getEmployeeDetails().subscribe({
  //     next: (result) => {
  //     },
  //   });
  // }

  public showReceiptPopup(receipt: any): void {
    this.receiptObject = new ReceiptModel();
    this.receiptObject.col1Name = 'Campaign';
    this.receiptObject.col3Value = receipt.donated;
    this.receiptObject.total_amount = receipt.donated;
    this.receiptObject.invoice_no = String(Math.floor(Math.random() * (10000 - 200 + 1)) + 200);
    this.receiptObject.invoice_to = receipt.name  ;
    this.receiptObject.invoice_date = new Date();
    this.receiptObject.payment_method = 'Card';
    this.receiptObject.col1Value = receipt.Campaign;
    this.sharedService.updateReceiptPopupState(true);
  }

  public calculateDaysLeft(endDate: string): string {
    const today = new Date();
    const end = new Date(endDate);
    const diffTime = end.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? diffDays.toString() : '0'; // for negative values
  }

  public navigateToCampaign(campaign: any) {
    this.router.navigate(['employee/campaigns/view'], {
      queryParams: { id: campaign?.id },
    });
  }


  // public closeCreateEmailPopup() {
  //   this.receiptDetails = false;
  // }

  // public openDetailsPopup() {
  //   this.receiptDetails = true;
  // }
}
